import * as types from '../action_types/filesActionTypes';

const initialState = {
	label: 'GÉNÉRAL',
	id: 0,
	folders: [],
	files: [],
};

function filesReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case types.RESET_FOLDERS:
			return initialState;

		case types.SET_FOLDERS:
			nextState = { ...state, ...action.folder };
			return nextState;

		case types.UPDATE_FOLDER:
			nextState = { ...state };
			nextState.folders[action.index] = {
				...nextState.folders[action.index],
				...action.value,
			};
			return nextState;

		default:
			return state;
	}
}

export default filesReducer;
