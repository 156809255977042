// module
import React, { Fragment } from 'react';
// components
import PictoTable from '../PictoTable';

const TableLease = props => {
	const { inventory_entry, inventory_exit, insurance, files, label, start, end } =
		props.table;
	return (
		<table className="table horizontal table-lease">
			<tbody>
				<tr>
					<th>Libellé</th>
					<td>{label}</td>
				</tr>
				<tr>
					<th>Début - Fin</th>
					<td>
						{start} - {end}
					</td>
				</tr>
				<tr>
					<th>Bail PDF</th>
					<td>
						{files.length
							? files.map((file, i) => <PictoTable key={i} file={file} />)
							: ' - '}
					</td>
				</tr>
				<tr>
					<th>Les états</th>
					<td>
						{inventory_entry.length ? (
							<>
								{inventory_entry.map((file, i) => (
									<PictoTable key={i} file={file} />
								))}{' '}
								Etat{inventory_entry.length > 1 && 's'} des lieux entrant
								{inventory_entry.length > 1 && 's'}
							</>
						) : null}
						{inventory_exit.length ? (
							<>
								{inventory_exit.map((file, i) => (
									<PictoTable key={i} file={file} />
								))}{' '}
								Etat{inventory_exit.length && 's'} des lieux sortant
								{inventory_exit.length > 1 && 's'}
							</>
						) : null}
						{!inventory_entry.length && !inventory_exit.length && ' - '}
					</td>
				</tr>
				<tr>
					<th>Dates assurance</th>
					<td>
						{insurance?.start} - {insurance?.end}
					</td>
				</tr>
				<tr>
					<th>Assurance PDF</th>
					<td>
						{insurance?.files.length
							? insurance.files.map((file, i) => (
									<PictoTable
										key={i}
										file={file}
										picto_class="picto-table"
									/>
							  ))
							: ' - '}
					</td>
				</tr>
			</tbody>
		</table>
	);
};

export default TableLease;
