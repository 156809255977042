import * as types from '../action_types/leasesActionTypes';

const initialState = {
	list: {},
	current: null,
	technical_visits: null,
};

function leasesReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case types.SET_LEASES:
			nextState = {
				...state,
				list: action.leases,
			};
			return nextState || state;

		case types.SET_CURRENT_LEASE:
			nextState = {
				...state,
				current: action.lease,
			};
			return nextState || state;

		case types.REMOVE_CURRENT_LEASE:
			nextState = {
				...state,
				current: null,
			};
			return nextState || state;

		case types.REMOVE_LEASES:
			return initialState;

		case types.SET_TECHNICAL_VISITS:
			nextState = {
				...state,
				technical_visits: action.technical_visits,
			};
			return nextState || state;

		default:
			return state;
	}
}

export default leasesReducer;
