//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import Title from '../../../components/Title';
import TableTenants from '../../../components/table/TableTenants';
import PrintButton from '../../../components/button/PrintButton';
import Pagination from '../../../components/Pagination';
import Loading from '../../../components/Loading';
// action
import { setLog } from '../../../action_creators/logActionCreators';
import {
	setTenants,
	removeTenants,
	setNbPagesTenants,
	setSoldeTenants,
	setTenantsExercice,
} from '../../../action_creators/tenantsActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { printTable } from '../../../functions/print';

class Tenants extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			current_page: 1,
			is_loading: false,
		};

		this.redirectToPage = this.redirectToPage.bind(this);
		this.responseManagment = responseManagment.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.checkPageParam();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		// Update tenants when page change
		if (prevProps.match.params.page !== this.props.match.params.page) {
			// Prevent infinite loading from page redirection during or after ajax request
			if (this.state.is_loading === true) {
				this.setState({ is_loading: false });
			}
			this.checkPageParam();
		}
	}

	checkPageParam() {
		let nb_current_page = this.props.match.params.page;

		if (nb_current_page === undefined) {
			// No page mentionned = page 1
			this.getTenants();
		} else if (isNaN(nb_current_page) || nb_current_page < 1) {
			// Page param is invalid
			this.redirectToPage();
		} else {
			nb_current_page = parseInt(nb_current_page);
			if (nb_current_page !== this.state.current_page) {
				// Set new page in state
				this.setState({ current_page: nb_current_page });
			}
			// Check if there are no tenants on current page
			if (!this.props.tenants.list[nb_current_page]) {
				this.getTenants(nb_current_page);
			}
		}
	}

	async getTenants(asked_page = 1) {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/compte-locataire?activity=${this.props.properties.current.id}`
				+ `&page=${asked_page}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			// Check if asked page match with API pages
			if (response.current_page > asked_page) {
				return this.redirectToPage();
			} else if (response.current_page > 0 && response.current_page < asked_page) {
				return this.redirectToPage(response.current_page);
			}
			if (!this.props.tenants.nb_pages) {
				this.props.setNbPagesTenants(response.nb_pages);
			}
			this.props.setTenants(response.tenants, response.current_page);
			this.props.setSoldeTenants(response.solde, response.solde_tresorerie);
			this.props.setTenantsExercice(response.exercice);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	/**
	 * Redirect the incident page of the given number
	 * @param {number|string} page_number
	 */
	redirectToPage(page_number = 1) {
		this.props.history.push(
			`/property/${this.props.match.params.id}/account/tenants/${page_number}`
		);
	}

	handlePrint(evt) {
		printTable(
			'table-tenants',
			`locataires-${this.props.properties.current.name}.pdf`,
			'Liste des locataires',
			this.props.properties.current
		);
	}

	goBack(evt) {
		this.props.history.push(`/property/${this.props.match.params.id}/account/`);
	}

	render() {
		const tenants = this.props.tenants;
		return (
			<>
				<Title title="Mes comptes" />
				<div className="list-tenants">
					<div className="page-title">
						<button
							className="btn back-btn"
							onClick={evt => this.goBack(evt)}
						>
							<img
								src="/images/pictogrammes/back-arrow-blue.svg"
								alt="retour"
							/>
						</button>
						<h2>Détails de mes comptes locataires</h2>
						<PrintButton print={this.handlePrint} />
					</div>
					<div className="table-wrapper">
						{this.state.is_loading === false ? (
							tenants.list[this.state.current_page]
							&& tenants.list[this.state.current_page].length ? (
								<TableTenants
									cols={['NOM', 'SOLDE', 'COMPTE']}
									table={
										this.props.tenants.list[this.state.current_page]
									}
									solde={this.props.tenants.solde}
									solde_treasury={this.props.tenants.solde_treasury}
									url_access={`/property/${this.props.match.params.id}/account/tenant_account/`}
								/>
							) : (
								<p>Il n'y aucun locataire.</p>
							)
						) : (
							<Loading />
						)}
					</div>
					{tenants.nb_pages > 1 && (
						<Pagination
							page_max={tenants.nb_pages}
							callback={this.redirectToPage}
							current_page={this.state.current_page}
						/>
					)}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		tenants: state.tenants,
	};
};
const mapDispatchToProps = {
	setLog,
	setTenants,
	setNbPagesTenants,
	setSoldeTenants,
	setTenantsExercice,
	removeTenants,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tenants));
