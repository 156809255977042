// module
import React from 'react';
import PropTypes from 'prop-types';

function ToggleButton(props) {
	return (
		<button
			className={`btn${
				props.current_value === props.active_value ? ' active' : ''
			}`}
			onClick={evt => props.toggle(props.active_value)}
		>
			<span>{props.label}</span>
		</button>
	);
}

ToggleButton.propTypes = {
	active_value: PropTypes.any,
	current_value: PropTypes.any,
	toggle: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
};

export default ToggleButton;
