// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter, Redirect } from 'react-router-dom';
// component
import FormPassword from '../components/form/FormPassword';
import FormUser from '../components/form/FormUser';
//actions
import { removeCurrentProperty } from '../action_creators/propertiesActionCreators';
import {
	setUser,
	setUserProfilePicture,
	updateUser,
} from '../action_creators/userActionCreators';
import { setLog } from '../action_creators/logActionCreators';
// functions
import { postRequest, responseManagment, responseBlobManagment } from '../functions/ajax';
// constants
import { STORED_USER } from '../constants/localStorage';
import Title from '../components/Title';
import { Link } from 'react-router-dom';

const form_items_user = [
	{
		type: 'email',
		name: 'email',
		label: `Email`,
		required: true,
	},
	{
		type: 'text',
		name: 'name',
		label: `Nom`,
	},
	{
		type: 'text',
		name: 'first_name',
		label: `Prénom`,
		required: false,
	},
	{
		type: 'texte',
		name: 'phone',
		label: `Téléphone`,
		required: false,
	},
];

const form_items_password = [
	{
		type: 'password',
		name: 'old_password',
		label: `Mot de passe actuel`,
		placeholder: 'Entrez votre mot de passe actuel',
		required: true,
	},
	{
		type: 'password',
		name: 'confirmation',
		label: `Confirmation du nouveau mot de passe`,
		placeholder: 'Entrez de nouveau votre nouveau mot de passe',
		required: true,
	},
];

class MyInfo extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.updateUser = this.updateUser.bind(this);
		this.updatePassword = this.updatePassword.bind(this);
		this.responseManagment = responseManagment.bind(this);
		this.responseBlobManagment = responseBlobManagment.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (Object.keys(this.props.properties.current).length) {
			this.props.removeCurrentProperty();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async updatePassword(value) {
		this.setState({ is_loading: true });
		let API_form_data = {
			oldPassword: value.old_password,
			newPassword1: value.new_password,
			newPassword2: value.confirmation,
		};

		const response = await postRequest(
			`/user-password`,
			API_form_data,
			this.props.user.token
		);

		if (this.responseManagment(response)) {
			this.props.setLog({
				type: 'success',
				message: 'Nouveau mot de passe enregistré.',
			});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async updateUser(value) {
		if (value.image) {
			this.updateProfilePicture(value.image);
		}

		const user = {
			name: value.name,
			first_name: value.first_name,
			email: value.email,
			phone: value.phone,
		};

		const response = await postRequest(`/user`, user, this.props.user.token);
		if (this.responseManagment(response)) {
			this.props.setLog({
				type: 'success',
				message: 'Vos informations ont été mises à jour.',
			});
			this.props.updateUser(user);
			user.cgu = this.props.user.cgu;
			user.token = this.props.user.token;
			localStorage.setItem(STORED_USER, JSON.stringify(user));
		}
	}

	async updateProfilePicture(profile_picture) {
		const response = await postRequest(
			`/profile-picture?width=165&height=165`,
			{ profile_picture },
			this.props.user.token
		);
		if (this.responseBlobManagment(response)) {
			const blob = URL.createObjectURL(response);
			this.props.setUserProfilePicture(blob);
			this.props.setLog({
				type: 'success',
				message: 'Image enregistrée.',
			});
		} else {
			this.props.setUserProfilePicture(false);
		}
	}

	render() {
		return (
			<>
				<Title title="Mon accès" />
				<div className="my-info">
					<div className="segment">
						<h2>Modifier vos informations</h2>
						<div className="my-info-form">
							<FormUser
								user={this.props.user}
								form_items={form_items_user}
								callback={this.updateUser}
								setLog={log => this.props.setLog(log)}
							/>
						</div>
					</div>
					<div className="segment">
						<h2>Modifier votre mot de passe</h2>
						<div className="my-info-password">
							<FormPassword
								form_items={form_items_password}
								callback={this.updatePassword}
							/>
						</div>
						{/* Redirection */}
						<Route path="/info">
							<Redirect to={`/info`} />
						</Route>
						{/* </Switch> */}
					</div>
				</div>
				<Link to="/logout" className="btn outline-btn white">
					Déconnexion
				</Link>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		properties: state.properties,
	};
};
const mapDispatchToProps = {
	setUser,
	updateUser,
	setUserProfilePicture,
	removeCurrentProperty,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyInfo));
