//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Title extends Component {
	render() {
		const { title } = this.props;

		return (
			<div className="hero">
				<div className="hero-title">
					<h1>{title}</h1>
					{this.props.children}
				</div>
				<div className="hero-menu">
					<div className="user-menu">
						{Object.keys(this.props.user).length ? (
							<span className="user-menu-title">
								{this.props.user.first_name
									? `${this.props.user.first_name} ${this.props.user.name[0]}.`
									: this.props.user.name}
							</span>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		properties: state.properties,
	};
};

export default withRouter(connect(mapStateToProps)(Title));
