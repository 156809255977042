import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { API_PDF_SRC_URL } from '../../constants/api';
import Loading from '../Loading';
import { colorOddRows } from './Table';

class TableFile extends Component {
	render() {
		const { folder_index, is_loading } = this.props;
		const { folders, files } = this.props.folder;
		if (is_loading && !folders?.length) {
			return <Loading />;
		}
		if (!folders?.length && !files?.length) {
			return <p>Ce dossier est vide.</p>;
		}
		return (
			<table className="table table-file">
				<thead>
					<tr>
						<th>Libellé</th>
						<th>Document</th>
					</tr>
				</thead>
				<tbody className="reverse-color">
					{folder_index === null && is_loading ? (
						<RowLoading />
					) : (
						folders.map((folder, i) => (
							<Fragment key={folder.id}>
								<RowFolder
									folder={folder}
									active={folder_index === i}
									handleFolder={this.props.handleFolder}
									i={i}
								/>
								{folder_index === i
									&& (is_loading ? (
										<RowLoading />
									) : (
										folder.files?.map((file, i) => (
											<RowFile
												key={i}
												file={file}
												className="file"
											/>
										))
									))}
							</Fragment>
						))
					)}
					{files.map((file, i) => (
						<RowFile
							key={i}
							file={file}
							className={colorOddRows(folders.length + i)}
						/>
					))}
				</tbody>
			</table>
		);
	}
}

const RowLoading = () => (
	<tr className="file">
		<td colSpan={2}>
			<Loading />
		</td>
	</tr>
);

const RowFolder = ({ folder, active = false, handleFolder, i }) => (
	<tr onClick={() => handleFolder(i)} className={colorOddRows(i)}>
		<td>{folder.label}</td>
		<td className="folder">
			<img
				src={`/images/pictogrammes/folder-blue.svg`}
				alt={`dossier ${folder.label}`}
				className="picto-folder"
			/>
			<img
				src="/images/pictogrammes/arrow-blue.svg"
				alt="arrow"
				className={`picto-arrow ${active ? 'active' : ''}`}
			/>
		</td>
	</tr>
);

const RowFile = ({ file, className }) => (
	<tr className={className}>
		<td>{file.label}</td>
		<td>
			<a href={API_PDF_SRC_URL + file.url} className="file-name">
				<img src="/images/pictogrammes/file-blue.svg" alt="fichier" />
			</a>
		</td>
	</tr>
);

export default withRouter(TableFile);
