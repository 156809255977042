//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

class AsideNav extends Component {
	render() {
		if (Object.keys(this.props.properties.current).length) {
			return (
				<>
					<nav className="grow nav-top">
						<ul>
							<li>
								<NavLink
									to={`/property/${this.props.properties.current.id}/dashboard`}
								>
									Tableau de bord
								</NavLink>
							</li>
							<li>
								<NavLink
									to={`/property/${this.props.properties.current.id}/incidents`}
								>
									Incidents
								</NavLink>
							</li>
							<li>
								<NavLink
									to={`/property/${this.props.properties.current.id}/data`}
								>
									Mes biens
								</NavLink>
							</li>
							<li>
								<NavLink
									to={`/property/${this.props.properties.current.id}/account`}
								>
									Mes comptes
								</NavLink>
							</li>
							<li>
								<NavLink
									to={`/property/${this.props.properties.current.id}/managment`}
								>
									Ma gestion
								</NavLink>
							</li>
							<li>
								<NavLink
									to={`/property/${this.props.properties.current.id}/administrative`}
								>
									Administratif
								</NavLink>
							</li>
						</ul>
					</nav>
					<nav className="nav-bottom">
						<ul>
							<li>
								<NavLink exact to="/">
									Mes propriétés
								</NavLink>
							</li>
							<li>
								<NavLink to="/info">Mon accès</NavLink>
							</li>
							<li>
								<NavLink to="/help">Aide & contact</NavLink>
							</li>
						</ul>
					</nav>
				</>
			);
		} else {
			return (
				<>
					<div className="grow" />
					<nav className="nav-bottom">
						<ul>
							<li>
								<NavLink exact to="/">
									Mes propriétés
								</NavLink>
							</li>
							<li>
								<NavLink to="/info">Mon accès</NavLink>
							</li>
							<li>
								<NavLink to="/help">Aide & contact</NavLink>
							</li>
						</ul>
					</nav>
				</>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
	};
};

export default withRouter(connect(mapStateToProps)(AsideNav));
