// module
import React, { Component } from 'react';
// components
import { TableBase } from './Table';
// functions
import {
	setAnimatedCellsHeight,
	toggleAnimatedCells,
} from '../../functions/animatedCells';
import { API_PDF_SRC_URL } from '../../constants/api';

class TableProcedures extends Component {
	constructor(props) {
		super(props);

		this.handleDeploy = this.handleDeploy.bind(this);
	}

	handleDeploy(evt) {
		setAnimatedCellsHeight();
		toggleAnimatedCells(evt.currentTarget.id);
	}

	render() {
		return (
			<TableBase className="table-judicial-procedures" cols={this.props.cols}>
				{this.props.table.map((el, i) => (
					<tbody key={i} className="table-hidden-rows">
						<tr
							id={`tbody-${i}`}
							className="tr-clickable"
							onClick={this.handleDeploy}
						>
							<td>{el.libelle}</td>
							<td>{el.locataire}</td>
							<td>{el.type}</td>
							<td>{el.avocat}</td>
							<td className="date">{el.date}</td>
							<td>{el.derniereEtape}</td>
							<td>
								{el.lastFiles.length
									? el.lastFiles.map((file, i) => (
											<a
												key={i}
												href={API_PDF_SRC_URL + file.url}
												title={file.label}
												className="table-picto"
											>
												{file.label}
											</a>
									  ))
									: null}
							</td>
						</tr>
						{!!el.etapes
							&& el.etapes.map((etape, j) => (
								<tr key={j} className={`hidden hideable tbody-${i}`}>
									<td />
									<td />
									<td />
									<td />
									<td>
										<div className="animated-cell">{etape.date}</div>
									</td>
									<td className="td-label">
										<div className="animated-cell">
											{etape.description}
										</div>
									</td>
									<td>
										{etape.files.length
											? etape.files.map((file, i) => (
													<a
														key={i}
														href={API_PDF_SRC_URL + file.url}
														title={file.label}
														className="table-picto"
													>
														{file.label}
													</a>
											  ))
											: null}
									</td>
								</tr>
							))}
					</tbody>
				))}
			</TableBase>
		);
	}
}

export default TableProcedures;
