// modules
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { BrowserRouter as Router } from 'react-router-dom';
// tools
import * as serviceWorker from './serviceWorker';
// components
import App from './App';
import ScrollToTop from './class/components/ScrollToTop';
// functions
import reducerCombiner from './class/reducers';
// style
import './styles/style.scss';
import './styles/bootstrap.css';
import ErrorBoundary from './class/components/ErrorBoundary';

let store = createStore(reducerCombiner);

render(
	<Provider store={store}>
		<Router>
			<ErrorBoundary>
				<ScrollToTop />
				<App />
			</ErrorBoundary>
		</Router>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
