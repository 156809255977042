// module
import React from 'react';
import PropTypes from 'prop-types';
// components
import { TableBase, colorOddRows } from './Table';
import { formatNumbers } from '../../functions';
import Lens from '../../../styles/images/pictogrammes/lens';

const TableLeases = props => {
	return (
		<TableBase className="table-leases" cols={props.cols}>
			<tbody>
				{props.table.map((lease, i) => (
					<tr
						key={i}
						className={`${colorOddRows(i) || ''} clickable hoverable`}
						onClick={() => props.viewLeaseDetails(lease.id)}
					>
						<td className="td-label">{lease.label}</td>
						<td>{lease.locataire}</td>
						<td>{lease.end}</td>
						<td>
							{lease.next_rent
								? `${lease.next_rent} - ${formatNumbers(
										lease.rent_amout
								  )}\u00A0€`
								: null}
						</td>
						<td>
							<Lens color={'#193b72'} />
						</td>
					</tr>
				))}
			</tbody>
		</TableBase>
	);
};

TableLeases.propTypes = {
	...TableBase.propTypes,
	viewLeaseDetails: PropTypes.func.isRequired,
};

export default TableLeases;
