import * as types from '../action_types/crgActionTypes';

export const setCrg = (crg) => ({
	type: types.SET_CRG,
	crg
});
export const setCrgShort = (crg, count) => ({
	type: types.SET_CRG_SHORT,
	crg,
	count
});
export const setCrgExercices = (exercices) => ({
	type: types.SET_CRG_EXERCICES,
	exercices
});
export const setCrgCurrentExercice = (exercice) => ({
	type: types.SET_CRG_CURRENT_EXERCICE,
	exercice
});
export const removeCrg = () => ({
	type: types.REMOVE_CRG
});