import React from 'react';

const Coin = ({ color }) => (
	<svg
		version="1.1"
		id="_x32_"
		xmlns="http://www.w3.org/2000/svg"
		width="30px"
		height="30px"
		viewBox="0 0 512 512"
		fill={color}
		className="d-flex"
	>
		<g>
			<path
				className="st0"
				d="M329.031,179.141c0,23.516-44.781,42.578-100.016,42.578c-55.25,0-100.031-19.063-100.031-42.578
		c0-23.531,44.781-42.609,100.031-42.609C284.25,136.531,329.031,155.609,329.031,179.141z"
			/>
			<path
				className="st0"
				d="M128.984,469.406c0,23.531,44.781,42.594,100.031,42.594c55.234,0,100.016-19.063,100.016-42.594v-10.563
		c0-3.25-1.625-6.281-4.359-8.094c-2.703-1.781-6.141-2.094-9.141-0.813c0,0-31.281,19.328-86.516,19.328
		c-55.25,0-86.547-19.328-86.547-19.328c-2.984-1.281-6.422-0.969-9.141,0.813c-2.719,1.813-4.344,4.844-4.344,8.094V469.406z"
			/>
			<path
				className="st0"
				d="M128.984,397.047c0,23.531,44.781,42.578,100.031,42.578c55.234,0,100.016-19.047,100.016-42.578v-10.578
		c0-3.25-1.625-6.281-4.359-8.094c-2.703-1.781-6.141-2.094-9.141-0.813c0,0-31.281,19.328-86.516,19.328
		c-55.25,0-86.547-19.328-86.547-19.328c-2.984-1.281-6.422-0.969-9.141,0.813c-2.719,1.813-4.344,4.844-4.344,8.094V397.047z"
			/>
			<path
				className="st0"
				d="M128.984,324.688c0,23.531,44.781,42.578,100.031,42.578c55.234,0,100.016-19.047,100.016-42.578v-10.594
		c0-3.25-1.625-6.281-4.359-8.094c-2.703-1.781-6.141-2.094-9.141-0.813c0,0-31.281,19.344-86.516,19.344
		c-55.25,0-86.547-19.344-86.547-19.344c-2.984-1.281-6.422-0.969-9.141,0.813c-2.719,1.813-4.344,4.844-4.344,8.094V324.688z"
			/>
			<path
				className="st0"
				d="M128.984,252.313c0,23.531,44.781,42.594,100.031,42.594c55.234,0,100.016-19.063,100.016-42.594V241.75
		c0-3.266-1.625-6.281-4.359-8.094c-2.703-1.781-6.141-2.094-9.141-0.813c0,0-31.281,19.313-86.516,19.313
		c-55.25,0-86.547-19.313-86.547-19.313c-2.984-1.281-6.422-0.969-9.141,0.813c-2.719,1.813-4.344,4.828-4.344,8.094V252.313z"
			/>
			<path
				className="st0"
				d="M484.625,93.406c0,23.5-44.781,42.563-100.016,42.563c-55.25,0-100.031-19.063-100.031-42.563
		c0-23.547,44.781-42.594,100.031-42.594C439.844,50.813,484.625,69.859,484.625,93.406z"
			/>
			<path
				className="st0"
				d="M484.594,373.063v10.578c0,23.531-44.781,42.578-99.938,42.578c-12.969,0-25.25-1.031-36.594-2.938V380.5
		c10.672,1.813,22.875,3.063,36.594,3.063c55.156,0,86.484-19.344,86.484-19.344c2.969-1.344,6.391-0.969,9.156,0.75
		C482.969,366.781,484.594,369.844,484.594,373.063z"
			/>
			<path
				className="st0"
				d="M484.594,300.75v10.594c0,23.516-44.781,42.563-99.938,42.563c-12.969,0-25.25-1.031-36.594-2.938v-42.875
		c10.672,1.813,22.875,3.063,36.594,3.063c55.156,0,86.484-19.344,86.484-19.344c2.969-1.234,6.391-0.969,9.156,0.844
		C482.969,294.469,484.594,297.438,484.594,300.75z"
			/>
			<path
				className="st0"
				d="M484.594,228.375v10.563c0,23.531-44.781,42.594-99.938,42.594c-12.969,0-25.25-1.063-36.594-2.969v-42.875
		c10.672,1.813,22.875,3.063,36.594,3.063c55.156,0,86.484-19.344,86.484-19.344c2.969-1.234,6.391-0.953,9.156,0.875
		C482.969,222.063,484.594,225.125,484.594,228.375z"
			/>
			<path
				className="st0"
				d="M484.594,155.969v10.563c0,23.531-44.781,42.578-99.938,42.578c-12.969,0-25.25-1.047-36.594-2.938v-27.063
		c0-5.906-1.234-11.328-3.609-16.391c11.438,2.188,24.859,3.719,40.203,3.719c47.047,0,76.781-14.094,84.484-18.188
		c1.344-0.75,2-1.156,2-1.156c2.969-1.328,6.391-0.938,9.156,0.766c1.234,0.859,2.281,1.922,2.953,3.234
		C484.109,152.531,484.594,154.25,484.594,155.969z"
			/>
			<path
				className="st0"
				d="M227.422,42.594c0,23.516-44.797,42.563-100.031,42.563S27.375,66.109,27.375,42.594
		C27.375,19.063,72.156,0,127.391,0S227.422,19.063,227.422,42.594z"
			/>
			<path
				className="st0"
				d="M109.906,332v42.781c-46.875-3.531-82.5-20.953-82.5-41.906v-10.578c0-3.234,1.625-6.281,4.297-8.109
		c2.75-1.797,6.188-2.078,9.141-0.844C40.844,313.344,65.609,328.578,109.906,332z"
			/>
			<path
				className="st0"
				d="M109.906,259.719v42.656c-46.875-3.516-82.5-20.938-82.5-41.906v-10.563c0-3.25,1.625-6.313,4.297-8.094
		c2.75-1.734,6.188-2.125,9.141-0.781C40.844,241.031,65.609,256.281,109.906,259.719z"
			/>
			<path
				className="st0"
				d="M109.906,187.313v42.766c-46.875-3.531-82.5-20.969-82.5-41.922v-10.563c0-3.25,1.625-6.297,4.297-8.094
		c2.75-1.828,6.188-2.109,9.141-0.875C40.844,168.625,65.609,183.875,109.906,187.313z"
			/>
			<path
				className="st0"
				d="M227.375,105.188v10.563c0,0.594,0,1.156-0.094,1.719c-47.25,0.406-94.703,14.203-111.172,40.594
		c-49.922-2.391-88.703-20.406-88.703-42.313v-10.563c0-3.25,1.625-6.281,4.297-8.094c2.75-1.813,6.188-2.094,9.141-0.766
		c0,0,31.344,19.328,86.594,19.328c55.156,0,86.5-19.328,86.5-19.328c1.625-0.766,3.438-0.953,5.234-0.766
		c1.344,0.188,2.672,0.766,3.922,1.531c2.281,1.531,3.703,3.906,4.094,6.563C227.375,104.141,227.375,104.703,227.375,105.188z"
			/>
			<path
				className="st0"
				d="M329.031,179.141c0,23.516-44.781,42.578-100.016,42.578c-55.25,0-100.031-19.063-100.031-42.578
		c0-23.531,44.781-42.609,100.031-42.609C284.25,136.531,329.031,155.609,329.031,179.141z"
			/>
		</g>
	</svg>
);

export default Coin;
