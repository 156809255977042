// module
import React from 'react';
// components
import PropTypes from 'prop-types';
import { formatNumbers } from '../../functions';
// function

const TableAdmin = props => (
	<table className="table table-admin horizontal">
		<tbody>
			<tr>
				<th>Nom</th>
				<td>{props.table.name}</td>
			</tr>
			<tr>
				<th>Adresse</th>
				<td>{props.table.address.full}</td>
			</tr>
			<tr>
				<th>Contact</th>
				<td>{props.table.email}</td>
			</tr>
			<tr>
				<th>Téléphone</th>
				<td>{props.table.phone}</td>
			</tr>
			<tr>
				<th>Mandat</th>
				<td>{props.table.start}</td>
			</tr>
			{(props.table.siret
				|| props.table.ape
				|| props.table.rcs
				|| props.table.capital) && (
				<tr>
					<th>Société</th>
					<td>
						{props.table.siret
							&& `Numéro de Siret\u00A0: ${props.table.siret} | `}
						{props.table.ape && `Code APE\u00A0: ${props.table.ape} | `}
						{props.table.rcs
							&& `Ville d'immatriculation\u00A0: ${props.table.rcs} | `}
						{props.table.capital
							&& `Capital\u00A0: ${formatNumbers(
								props.table.capital
							)}\u00A0€`}
					</td>
				</tr>
			)}
			{props.table.financial_guarantor
				&& Object.keys(props.table.financial_guarantor).length > 0 && (
					<tr>
						<th>Garant</th>
						<td>
							{props.table.financial_guarantor.description
								&& `${props.table.financial_guarantor.description} | `}
							{props.table.financial_guarantor.amount
								&& `Montant Maximal\u00A0: ${formatNumbers(
									props.table.financial_guarantor.amount
								)}\u00A0€`}
						</td>
					</tr>
				)}
		</tbody>
	</table>
);

TableAdmin.propTypes = {
	table: PropTypes.object.isRequired,
	className: PropTypes.string,
};

export default TableAdmin;
