// module
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// components
import { colorOddRows, TableBase } from './Table';
// const
import { API_PDF_SRC_URL } from '../../constants/api';

class  TableIncidents extends Component {
	constructor(props) {
		super(props);

		this.toggleTr = this.toggleTr.bind(this);
	}

	componentDidUpdate(prevProps) {
		// Re-hide hiddenable tbody when page change
		if (prevProps.current_page !== this.props.current_page) {
			let can_be_hidden = document.getElementsByClassName(`hideable`);

			for (let el of can_be_hidden) {
				if (!el.classList.contains('hidden')) {
					el.classList.add('hidden');
				}
			}
		}
	}

	toggleTr(evt) {
		let can_be_hidden = document.querySelectorAll(
			`.hideable.${evt.currentTarget.id}`
		);
		// if 0 is hidden, next are hidden, vice versa
		if (can_be_hidden[0] && can_be_hidden[0].classList.contains('hidden')) {
			for (let el of can_be_hidden) {
				el.classList.remove('hidden');
			}
		} else {
			for (let el of can_be_hidden) {
				el.classList.add('hidden');
			}
		}
	}

	render() {
		return (
			<TableBase className="table-incidents" cols={this.props.cols}>
				{this.props.table.map((el, i) => (
					<tbody key={i} className="table-hidden-rows reverse-color">
						<tr
							id={`tbody-${i}`}
							className={colorOddRows(i)}
							onClick={this.toggleTr}
						>
							<td className="date">{el.date}</td>
							<td>{el.label}</td>
							<td className="td-label">
								<div className="ellipsed">{el.description}</div>
							</td>
							<td>
								<img
									src="/images/pictogrammes/lens-blue.svg"
									alt="bouton détails"
									className={`table-picto tbody-${i}`}
								/>
							</td>
						</tr>
						{Object.values(el.etapes).map((value, j) => (
							<tr key={j} className={`hidden hideable tbody-${i}`}>
								<td>
									<div className="animated-cell">{value.date}</div>
								</td>
								<td>
									<div className="animated-cell">{el.label}</div>
								</td>
								<td>
									<div className="animated-cell td-label ellipsed">
										{value.description}
									</div>
								</td>
								<td className='has-image'>
									<div className="animated-cell">
										{value.files.map((file, k) => (
											<a href={API_PDF_SRC_URL + file} key={k}>
												<img
													className="table-picto"
													src={`/images/pictogrammes/image-white.svg`}
													alt="Pictogramme pièce jointe"
												/>
												<span className="sr-only">
													Pièce jointe
												</span>
											</a>
										))}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				))}
			</TableBase>
		);
	}
}

TableIncidents.propTypes = {
	// ...Table.propTypes,
	current_page: PropTypes.any.isRequired,
};

export default TableIncidents;
