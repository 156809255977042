//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import TableProcedures from '../../../components/table/TableProcedures';
import TableReminders from '../../../components/table/TableReminders';
import Loading from '../../../components/Loading';
//action
import { setReminders } from '../../../action_creators/remindersActionCreators';
import { setProcedures } from '../../../action_creators/proceduresActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import Title from '../../../components/Title';
import TrippleToggleButtons from '../../../components/button/TrippleToggleButtons';
import MyFiles from '../MyFiles';
import FileToggleButton from '../../../components/button/FileToggleButton';

class MyBadNews extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			are_loading_reminders: false,
			are_loading_procedures: false,
			are_loading_files: false,
			activeValue: true,
			layout: 'table',
		};

		this.responseManagment = responseManagment.bind(this);
		this.toggleValue = this.toggleValue.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getProcedures();
		this.getReminders();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getProcedures() {
		if (!this.props.procedures.list.length) {
			this.setState({ are_loading_procedures: true });
			const response = await getRequest(
				`/procedures-judiciaires?activity=${this.props.properties.current.id}`,
				this.props.user.token
			);

			if (this.responseManagment(response)) {
				this.props.setProcedures(response.procedures);
			}

			if (this._isMounted) {
				this.setState({ are_loading_procedures: false });
			}
		}
	}

	async getReminders() {
		if (!this.props.reminders.list.length) {
			this.setState({ are_loading_reminders: true });
			const response = await getRequest(
				`/relance?activity=${
					this.props.properties.current.id
				}&acquitter=${false}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setReminders(response.relances);
			}

			if (this._isMounted) {
				this.setState({ are_loading_reminders: false });
			}
		}
	}

	toggleValue(value) {
		this.setState({ activeValue: value });
	}

	handleToggle(value) {
		this.setState({
			layout: value,
		});
	}

	render() {
		const { layout } = this.state;
		return (
			<>
				<Title title="Administratif" />
				<div className="my-bad-news responsive-column">
					<div className="administrative-menu">
						<TrippleToggleButtons
							value={this.state.activeValue}
							toggle={this.toggleValue}
							label_true="Relances"
							label_false="Procédures judiciaires"
							label_all="Mes fichiers"
						/>
						{this.state.activeValue === null && (
							<FileToggleButton
								layout={layout}
								onClick={this.handleToggle}
							/>
						)}
					</div>
					{this.state.are_loading_reminders === false ? (
						this.props.reminders.list.length
						&& this.state.activeValue === true ? (
							<div className="table-wrapper">
								<TableReminders
									cols={[
										'DATE',
										'LOCATAIRE',
										'MONTANT',
										'RESTANT',
										'TYPE',
										'DOCUMENTS',
									]}
									table={this.props.reminders.list}
									picto_color={this.props.properties.current.syndic}
									property={this.props.properties.current.id}
								/>
							</div>
						) : (
							this.state.activeValue === true && <p>Aucune relance.</p>
						)
					) : (
						<Loading />
					)}
					{this.state.are_loading_procedures === false ? (
						this.props.procedures.list.length
						&& this.state.activeValue === false ? (
							<div className="table-wrapper">
								<TableProcedures
									cols={[
										'LIBELLÉ',
										'LOCATAIRE/DÉFENDEUR',
										'TYPE',
										'AVOCAT',
										'DATE',
										'OBSERVATION',
										'DOCUMENTS',
									]}
									table={this.props.procedures.list}
									picto_color={this.props.properties.current.syndic}
									property={this.props.properties.current.id}
								/>
							</div>
						) : (
							this.state.activeValue === false && (
								<p>Aucune procédure judiciaire.</p>
							)
						)
					) : (
						<Loading />
					)}
					{this.state.activeValue === null ? (
						<MyFiles layout={this.state.layout} />
					) : (
						this.state.activeValue === null && <p>Aucun dossier</p>
					)}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		procedures: state.procedures,
		reminders: state.reminders,
	};
};

const mapDispacthToProps = {
	setProcedures,
	setReminders,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyBadNews));
