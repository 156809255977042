//actions
import { removeAccount } from '../action_creators/accountActionCreators';
import { removeBank } from '../action_creators/banksActionCreators';
import { removeLeases } from '../action_creators/leasesActionCreators';
import { removeLots } from '../action_creators/lotsActionCreators';
import { removeTenants } from '../action_creators/tenantsActionCreators';
import { removeCrg } from '../action_creators/crgActionCreators';
import { removeHonoraries } from '../action_creators/honorariesActionCreators';
import { removeProfit } from '../action_creators/profitActionCreators';
import { removeIncidentsAndFilters } from '../action_creators/incidentsActionCreators';
import { removeReminders } from '../action_creators/remindersActionCreators';
import { removeProcedures } from '../action_creators/proceduresActionCreators';
import { removeAdmin } from '../action_creators/adminActionCreators';
import { resetFolders } from '../action_creators/filesActionCreators';
import { removeNotifications } from '../action_creators/notificationsActionCreators';

export default {
	removeAccount,
	removeBank,
	removeIncidentsAndFilters,
	removeLeases,
	removeLots,
	removeTenants,
	removeCrg,
	removeHonoraries,
	removeProfit,
	removeProcedures,
	removeReminders,
	removeAdmin,
	resetFolders,
	removeNotifications,
};
