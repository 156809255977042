// module
import React, { Component, Fragment } from 'react';
// components
import { TableBase, colorOddRows } from './Table';
import TdAmount from './TdAmount';
// const
import { API_PDF_SRC_URL } from '../../constants/api';
import FileDownloadedFromAPI from '../FileDownloadedFromAPI';

const TdElsGroup = ({ el, activity, negative }) => (
	<Fragment>
		<td className="td-label">{el.label}</td>
		<TdAmount value={el.value - el.tva} negative={negative} />
		<TdAmount value={el.tva} negative={negative} />
		<TdAmount value={el.value} negative={negative} />
		<td>
			{el.type === 'Honoraires' ? (
				<FileDownloadedFromAPI
					uri={`/honoraire-facture/${el.id_honoraire}?activity=${activity}`}
					filename={`${el.type}-${el.id_honoraire}-${el.date}`}
					className="picto-table-wrapper"
				>
					<img
						src="/images/pictogrammes/file-blue.svg"
						alt="Télécharger la facture de l'honoraire"
						title={`Facture de l'honoraire du ${el.date}`}
						className="table-picto"
					/>
				</FileDownloadedFromAPI>
			) : (
				el.files.length > 0
				&& el.files.map((file, i) => (
					<a key={i} href={API_PDF_SRC_URL + file.url} className="table-picto">
						{file.label}
					</a>
				))
			)}
		</td>
	</Fragment>
);

const TdAmountsTotalGroup = props => (
	<Fragment>
		<TdAmount value={props.value.total_ht} negative={props.negative} />
		<TdAmount value={props.value.total_tva} negative={props.negative} />
		<TdAmount value={props.value.total_ttc} negative={props.negative} />
		<td></td>
	</Fragment>
);

class TableProfit extends Component {
	render() {
		return (
			<>
				<TableBase className="table-profit" cols={this.props.cols}>
					<tbody className="reverse-color">
						{this.props.table.products.list.map((product, i) => (
							<tr key={i} className={colorOddRows(i)}>
								<TdElsGroup el={product} activity={this.props.activity} />
							</tr>
						))}
						<tr className="subtotal">
							<td>TOTAL RECETTES</td>
							<TdAmountsTotalGroup value={this.props.table.products} />
						</tr>
						<tr className="table-column">
							<td>DÉPENSES</td>
							<td colSpan={this.props.cols.length - 1}></td>
						</tr>
						{this.props.table.charges.list.map((charge, i) => (
							<tr key={i} className={colorOddRows(i)}>
								<TdElsGroup
									el={charge}
									negative={true}
									activity={this.props.activity}
								/>
							</tr>
						))}
						<tr className="subtotal">
							<td>TOTAL DÉPENSES</td>
							<TdAmountsTotalGroup
								value={this.props.table.charges}
								negative={true}
							/>
						</tr>
						<tr className='bg-transparent'>
							<td colSpan={this.props.cols.length} />
						</tr>
						<tr className="total rentability">
							<td>RENTABILITÉ</td>
							<TdAmountsTotalGroup value={this.props.table.result} />
						</tr>
					</tbody>
				</TableBase>
			</>
		);
	}
}

export default TableProfit;
