//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
import Loading from '../../../../components/Loading';
//action
import { setLog } from '../../../../action_creators/logActionCreators';
import {
	setGlobalAccountAmounts,
	setAccountExercices,
	setAccountCurrentExercice,
} from '../../../../action_creators/accountActionCreators';
// functions
import { getRequest, responseManagment } from '../../../../functions/ajax';
// constants
import { formatNumbers } from '../../../../functions';
import { SelectorItemObject } from '../../../../components/form/item/SelectorItem';

class OwnerAccountCard extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async UNSAFE_componentWillMount() {
		if (!Object.keys(this.props.account.global_amounts).length) {
			this.getAccount();
		}
	}

	componentDidUpdate(prevProps) {
		const account = this.props.account;
		// Filter change exercice
		if (prevProps.account.current_exercice !== account.current_exercice) {
			this.getAccount();
		}
	}

	async getAccount() {
		this.setState({ is_loading: true });
		const current_exercice =
			this.props.account.exercices[this.props.account.current_exercice];
		const response = await getRequest(
			`/compte-proprietaire-graph?activity=${this.props.properties.current.id}`
				+ (current_exercice
					? `&start=${current_exercice.start}&end=${current_exercice.end}`
					: ''),
			this.props.user.token
		);

		if (
			this.responseManagment(response)
			&& !(response.sum_rent === 0 && response.deposit === 0)
		) {
			this.props.setGlobalAccountAmounts({
				sum_rent: response.sum_rent,
				sum_deposit: response.sum_deposit,
			});
			if (!this.props.account.exercices.length) {
				this.props.setAccountExercices(response.exercices);
				this.props.setAccountCurrentExercice(response.exercice);
			}
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handleExercice(evt) {
		this.props.setAccountCurrentExercice(evt.target.value);
	}

	render() {
		const amounts = this.props.account.global_amounts;
		return (
			<div className="card unscrollable owner-account">
				<div className="card-header">
					<h2>Compte propriétaire</h2>
				</div>
				<div className="card-body">
					{this.props.account.exercices.length > 0 && (
						<div className="standfirst">
							<SelectorItemObject
								id="select-account-exerice"
								label="Période"
								current={this.props.account.current_exercice}
								options={this.props.account.exercices}
								callback={this.handleExercice}
							/>
						</div>
					)}
					{this.state.is_loading === false ? (
						Object.keys(amounts).length ? (
							<div className="table-wrapper">
								<table className="table horizontal table-owner-account">
									<tbody>
										<tr>
											<th>
												Montant appelé au locataire sur la période
											</th>
											<td>
												{formatNumbers(amounts.sum_rent || 0)} €
											</td>
										</tr>
										<tr>
											<th>
												Montant réglé au propriétaire sur la
												période
											</th>
											<td>
												{formatNumbers(-amounts.sum_deposit || 0)}{' '}
												€
											</td>
										</tr>
										<tr>
											<th>Montant restant</th>
											<td>
												{formatNumbers(
													amounts.sum_rent + amounts.sum_deposit
												)}{' '}
												€
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						) : (
							<p>Aucun montant n'est disponible pour cette période.</p>
						)
					) : (
						<Loading />
					)}
					<div className="btn-wrapper">
						<Link
							className="btn main-btn"
							to={`/property/${this.props.match.params.id}/account/owner_account`}
						>
							Voir détails
						</Link>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		account: state.account,
	};
};
const mapDispatchToProps = {
	setGlobalAccountAmounts,
	setAccountExercices,
	setAccountCurrentExercice,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OwnerAccountCard));
